@import 'libs/wallex-core/src/styles/variables';
@import 'libs/wallex-core/src/styles/mixins';

$form-control-padding: $spacer-1 $spacer-2;

.control-label,
.control-text {
  font-size: $font-size-form;
}

.form-control {
  height: 56px;

  border-radius: $border-radius;
  background: var(--item-background);

  &:focus,
  & {
    color: var(--main-text);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px var(--item-background) inset !important;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: var(--main-text) !important;
    caret-color: var(--main-text) !important;
  }
}

.form-control::placeholder {
  color: var(--secondary-text);
}

.form-group {
  min-height: 120px;
  margin-bottom: 0;
}

.form-group-small {
  min-height: 108px;
  margin-bottom: 0;
}

.check-box-group {
  min-height: 61px;
  margin-bottom: 6px;

  label {
    display: inline;

    margin-bottom: 0;
  }

  div {
    @include flex(center, flex-start);
  }
}

.clear-field {
  position: absolute;
  z-index: -1;

  overflow: hidden;

  width: 0;
  height: 0;

  pointer-events: none;

  opacity: 0;
}

.error-message {
  margin-top: 4px;
  margin-right: 14px;

  color: var(--main-red);

  @include text(var(--font-base-regular), $font-size-small, 22px, 400);
}

.required-asterisk {
  color: var(--main-red);
}

label.formLabel {
  display: block;

  margin: 0 0 8px;

  color: var(--main-text);
}

label {
  margin-bottom: 0;
}

.p-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.p-password {
  & > input {
    width: 100%;
  }
}

p-password div {
  position: relative;

  width: 100%;
}

p-password .pi {
  position: absolute;
  top: 50%;
  right: 1rem !important;

  margin-top: -0.5rem;

  color: var(--secondary-text);
}

p-password .disabled-key .pi {
  right: 54px !important;

  margin-top: -0.625rem;

  cursor: pointer;

  color: #9b9ea4;

  font-size: 1.25rem;
}

.disabled-key > .p-inputtext {
  padding-right: 4.8rem;

  cursor: default !important;
  pointer-events: auto !important;

  opacity: 1 !important;
}

p-dropdown > .p-dropdown {
  width: 100%;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: var(--item-border);
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown.control-invalid {
  border-color: var(--main-red);
}

.form-control {
  border-color: var(--item-border);
}

.form-control:disabled,
p-inputmask .p-inputtext:disabled {
  opacity: 1;
  color: var(--secondary-text) !important;
  background: var(--item-disabled-background);
  -webkit-text-fill-color: var(--secondary-text) !important;
}

.ng-invalid.control-invalid,
input.control-invalid,
.form-control:focus.ng-invalid.control-invalid,
.ng-invalid.ng-touched.control-invalid,
.ng-invalid.ng-dirty.control-invalid,
p-password.control-invalid input,
.control-invalid {
  border-color: var(--main-red) !important;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext,
p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: var(--item-border);
}

.p-input-icon-right.control-invalid > .p-inputtext {
  border-color: var(--main-red) !important;
}

p-inputmask > .p-inputtext.control-invalid {
  border-color: var(--main-red);
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: var(--item-border);
}

.p-checkbox.control-invalid .p-checkbox-box {
  border-color: var(--main-red) !important;
}

.p-inputtext {
  width: 100%;
  padding: 16px;

  border-radius: $border-radius;
  background: var(--item-background);
}

.p-dropdown-filter-container .p-inputtext {
  padding-top: 9px;
  padding-bottom: 9px;
}

.p-dropdown-filter-icon {
  position: absolute;
  top: 50%;
  left: 0.5rem;

  margin-top: -0.5rem;

  cursor: text;
  pointer-events: none;
}

.p-dropdown-panel {
  border: 1px solid var(--item-border);
  background: var(--item-background);
  box-shadow: unset;
}

.p-dropdown-panel .p-dropdown-header {
  background: var(--dropdown-search-background);
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-left: 2rem;

  background: var(--grey-background);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item span,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: var(--main-text);

  @include text(var(--font-base-regular), $font-size-regular, 24px, 400);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight, .p-disabled):hover {
  color: var(--main-text) !important;
  background: var(--section-border) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  opacity: 0.45;
  background: transparent;
}

.p-dropdown-header {
  padding-top: 16px;

  background: var(--base-one);
}

.dropdown-invalid .p-dropdown,
.dropdown-invalid .p-dropdown:not(.p-disabled):hover {
  border-color: var(--main-red) !important;
}

// Matches the bordering and padding applied to form controls
.bordered-form-group {
  padding: $form-control-padding;

  border: var(--item-border) solid $input-border-width;
  border-radius: $border-radius-sm;
}

.p-dropdown {
  height: 56px;

  border: 1px solid var(--item-border);
  border-radius: $border-radius;
  background: var(--item-background);
}

.form-absolute {
  .p-dropdown {
    border: 1px solid var(--item-border);
    border-radius: $border-radius;
  }
}

.p-inputtext {
  color: var(--main-text) !important;
  border-color: var(--item-border);
  border-radius: $border-radius;

  @include text(var(--font-base-regular), $font-size-regular, 24px, 400);
}

.flex-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .form-group {
    width: calc(50% - 12px);
  }
}

.p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext::placeholder {
  color: var(--secondary-text) !important;
}

p-dropdown .dropdown.p-disabled {
  opacity: 1;
  background: var(--item-disabled-background);

  span {
    color: var(--secondary-text) !important;
  }
}

::placeholder,
:input-placeholder,
::input-placeholder {
  opacity: 1;
  color: var(--secondary-text);
}

input::-webkit-autofill,
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  margin-right: 20px;
}

.error-message ~ .error-message {
  display: none;
}

// grey dropdown

p-dropdown .grey-dropdown {
  height: 44px;

  border: none;
  border-radius: $border-radius-sm;
  background: var(--item-secondary-background);

  .p-dropdown-label {
    padding: 11px 16px;
  }
}

p-inputmask.control-invalid input,
p-checkbox.control-invalid .p-checkbox-box {
  border-color: var(--main-red) !important;
}

.form-control:focus {
  border-color: var(--hover-primary);
  background: var(--item-background);
}

.p-checkbox .p-checkbox-box {
  border-color: var(--item-border);
  background: var(--item-background);
}

.p-checkbox .p-checkbox-box.p-focus {
  box-shadow: none !important;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--button-primary);
  background: var(--button-primary);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--button-primary);
}

.p-checkbox .p-checkbox-box:focus-visible {
  border-color: var(--hover-primary);
}

.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus-visible {
  border-color: var(--hover-primary);
}

.p-dropdown:not(.p-disabled):hover,
.p-dropdown.p-focus {
  border-color: var(--hover-primary) !important;
  box-shadow: none !important;
}

.dropdown-field {
  &__empty {
    &-text {
      margin: 0 0 8px;

      text-align: center;

      @include text(var(--font-base-regular), $font-size-regular, 24px, 400);
    }

    &-link {
      display: block;

      text-align: center;

      @include text(var(--font-base-regular), $font-size-regular, 24px, 400);
    }
  }
}

.read-only-field {
  max-width: $max-input-width;
  height: $input-height;
  padding: 0 16px;

  border: 1px solid var(--item-border);
  border-radius: $border-radius;
  background: var(--grey-background);
}

@media (max-width: $mobile-xmd-breakpoint) {
  .p-dropdown-panel {
    width: 100%;
  }
}

// light dropdown
.light-dropdown {
  .p-dropdown-label {
    padding: 0;
  }

  .p-dropdown {
    height: auto;

    border: none;
    gap: 7px;
  }

  .p-dropdown-trigger {
    width: 20px;
  }

  .p-dropdown-panel {
    width: max-content;

    border-radius: $border-radius;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
  }

  .p-dropdown-items .p-dropdown-item.p-highlight {
    opacity: 1;
    color: var(--main-text);
    background: var(--hint-background);
  }
}
