@import 'libs/wallex-core/src/styles/variables';
@import 'libs/wallex-core/src/styles/mixins';

body {
  background: var(--body-background);
}

body::-webkit-scrollbar {
  width: $border-radius;
  height: $border-radius;

  background-color: var(--body-background);
}

body::-webkit-scrollbar-thumb {
  opacity: 0.4;
  border-radius: $border-radius;
  background: var(--scrollbar-color);
}

.limited-width {
  max-width: 1920px;
}

.cyrillic {
  --font-base-light: montserrat-light;
  --font-base-regular: montserrat-regular;
  --font-base-medium: montserrat-medium;
  --font-base-bold: montserrat-bold;
  --font-base-semiBold: montserrat-semibold;
}

.overflow-hidden {
  overflow: hidden;
}

.html-string {
  p {
    color: var(--main-text);

    @include text(var(--font-base-regular), $font-size-small, 22px, 400);
  }

  blockquote {
    margin: 22px auto;
  }

  img {
    max-width: 100%;
  }

  h1,
  p,
  h2,
  h3 {
    margin: 0 0 22px;
  }

  h1 {
    @include text(var(--font-base-medium), $font-size-xbig, 22px, 500);
  }

  h2 {
    @include text(var(--font-base-medium), $font-size-big, 22px, 500);
  }

  h3 {
    @include text(var(--font-base-medium), $font-size-large, 22px, 500);
  }

  strong {
    @include text(var(--font-base-bold), $font-size-small, 22px, 700);
  }
}

.rate-green,
.rate-red {
  display: block;

  width: 63px;

  @include text(var(--font-sans-regular), $font-size-small, 22px, 400);
}

span.loader.progress {
  display: block;

  margin-bottom: 0;

  border-radius: 100px;
}

.hidden {
  position: absolute;
  z-index: -1;

  pointer-events: none;

  opacity: 0;
}

.hide-element {
  position: absolute;
  z-index: -1;

  width: 0;
  height: 0;

  pointer-events: none;

  opacity: 0;
}

.reset-btn {
  margin: 0;
  padding: 0;

  cursor: pointer;

  border: none;
  border-radius: 0;
  background: none;
  box-shadow: none;

  &.disabled-button {
    pointer-events: none;

    color: var(--grey-primary) !important;
    background: transparent;
  }
}

.dialog-bold {
  @include text(var(--font-base-medium), $font-size-small, 22px, 500);
}

.default-wrapper {
  display: block;

  max-width: 760px;
  margin: 0 auto;
  padding: 24px 0 0;
}

.default-wrapper-xl {
  display: block;

  max-width: 956px;
  margin: 0 auto;
  padding: 24px 0 0;
}

.no-messages-container {
  width: 69.86%;
  min-width: 250px;
  margin: calc(50vh - 237.5px) auto 24px auto;
  padding-top: 64px;

  text-align: center;

  background: url('/assets/transactions.svg') no-repeat center top;
}

.status {
  height: 28px;
  padding: 3px 8px;

  white-space: nowrap;
  text-transform: capitalize;

  border-radius: 6px;

  @include text(var(--font-base-regular), $font-size-small, 22px, 400);
}

@media (max-width: $intercom-full-width-breakpoint) {
  .intercom-namespace {
    div:first-child {
      position: fixed !important;
      z-index: 2147483002 !important;

      width: 100% !important;
      height: 100% !important;
      max-height: none !important;

      opacity: 1 !important;
      border-radius: 0 !important;
      inset: 0 !important;
    }
  }
}

.intercom-namespace .intercom-messenger-frame {
  z-index: 2147483002 !important;
}
